<template>
  <va-card title="Mailer">
    <va-button
      :to="{ name: 'shkil-settings-mailer-edit'}"
    >
      Створити
    </va-button>
    <va-data-table
      :fields="fields"
      :data="items"
      no-pagination
    >
      <template slot="marker" slot-scope="props">
        <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
      </template>

      <template slot="actions" slot-scope="props">
        <va-button
          small
          color="primary"
          @click="testConnection(props.rowData.id)"
        >
          Connection
        </va-button>
        <va-button
          small
          color="info"
          :to="{ name: 'shkil-settings-mailer-edit', params: { id: props.rowData.id } }"
        >
          Редагувати
        </va-button>
        <va-button
          v-if="props.rowData.is_default !== 1"
          @click="remove(props.rowData.id)"
          small
          color="danger"
          class="ma-0">
          Видалити
        </va-button>
      </template>
    </va-data-table>
  </va-card>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  data () {
    return {
      items: [],
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    fields () {
      return [{
        name: 'label',
        title: 'Label',
        width: '15%',
      },
      {
        name: 'subject',
        title: 'Subject',
        width: '15%',
      },
      {
        name: 'send_from',
        title: 'Send From',
        width: '20%',
      },
      {
        name: 'send_to',
        title: 'Send To',
        width: '20%',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    testConnection (id) {
      axios.get(`${process.env.VUE_APP_SHKIL_API_URL}/admin/mailer/test/${id}?lang=${this.locale}`)
        .then(response => {
          this.showToast('Successfull connection')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    remove (id) {
      if (confirm('Дійсно видалити?')) {
        axios.delete(`${process.env.VUE_APP_SHKIL_API_URL}/admin/mailer/${id}?lang=${this.locale}`)
          .then(response => {
            const idx = this.items.findIndex(u => u.id === id)
            this.items.splice(idx, 1)
            this.showToast('Success')
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_SHKIL_API_URL}/admin/mailer?lang=${this.locale}`)
        .then(response => {
          console.log(response.data)
          this.items = response.data
          this.items.forEach((item) => {
            item.published = !!item.published
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
</style>
